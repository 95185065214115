
import { Component, Vue } from 'vue-property-decorator'
import { Page, Table } from '../../types/common'
import { PageType } from '../../types/workGuide'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'WorkGuideList',
  filters: {
    isShowFilters (value: number) {
      return value === 0 ? '否' : value === 1 ? '是' : ''
    }
  }
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    guideType: '',
    workGuideTile: '',
    tagName: '',
    isShow: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        label: '作业指导标题',
        prop: 'workGuideTile',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '作业指导类型',
        prop: 'guideTypeName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '作业内容分类',
        prop: 'workContentTypeName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '标签',
        prop: 'tagList',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '是否展示',
        prop: 'isShow',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '阅读量',
        prop: 'readingCounts',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '更新日期',
        prop: 'utime',
        minWidth: '120',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private typeList = []
  private GuideTypeList = []

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
    this.getGuideTypeList()
  }

  // 获取作业指导类型列表
  getGuideTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'guideType' }).then(res => {
      this.GuideTypeList = res.guideType || []
      console.log(this.GuideTypeList)
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.workGuide.workGuidePage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'workGuideDetail',
      params: { id }
    })
  }

  onAdd () {
    this.$router.push({
      name: 'workGuideAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'workGuideUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.workGuide.workGuideDelete, {
          workGuideId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
          .catch((err) => {
            this.$message.error(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
